import styled from "@emotion/styled";
import { css } from "@emotion/react";
import type { ButtonSize } from "../types";

const buttonIconStyle = (size: ButtonSize) => {
  switch (size) {
    case "big":
      return css`
        width: 16px;
        height: 16px;
        margin-inline-start: 12px;
      `;
    case "large":
      return css`
        width: 16px;
        height: 16px;
        margin-inline-start: 8px;
      `;
    case "medium":
      return css`
        width: 12px;
        height: 12px;
        margin-inline-start: 6px;
      `;
    case "small":
      return css`
        width: 12px;
        height: 12px;
        margin-inline-start: 4px;
      `;
    default:
      return null;
  }
};

export const StyledButtonIcon = styled.span<{
  size: ButtonSize;
}>`
  display: inline-flex;
  align-self: center;
  flex-shrink: 0;

  ${({ size }) => buttonIconStyle(size)};
`;
