import { TextProps } from "./types";
import * as S from "./styled";

const Text = ({
  color,
  ellipsis,
  fontSize,
  weight,
  children,
  letterSpacing,
  lineHeight,
  opacity,
  textAlign,
  whiteSpace,
  ...props
}: TextProps) => {
  return (
    <S.Text
      color={color}
      ellipsis={ellipsis}
      fontSize={fontSize}
      weight={weight}
      letterSpacing={letterSpacing}
      lineHeight={lineHeight}
      opacity={opacity}
      textAlign={textAlign}
      whiteSpace={whiteSpace}
      {...props}
    >
      {children}
    </S.Text>
  );
};
Text.defaultProps = {
  fontSize: "14px",
  ellipsis: false,
  weight: 500,
  letterSpacing: "initial",
  lineHeight: "initial",
  textAlign: "center",
};

export default Text;
