import styled from "@emotion/styled";
import { css, SerializedStyles } from "@emotion/react";
import type {
  ButtonColorScheme,
  ButtonColorType,
  ButtonVariantType,
  ButtonSize,
} from "./types";
import { LIGHT_THEME } from "@src/common/theme/theme";
import type { Theme } from "@emotion/react";

const containedButtonStyles: {
  [key in ButtonColorScheme]: (theme: Theme) => SerializedStyles;
} = {
  primary: (theme: Theme) =>
    css({
      color: theme.colors["bg/Deep"],
      backgroundColor: theme.colors["bg/InversePrimary"],

      ":hover, :active": {
        backgroundColor: theme.colors.gray800,
      },
      ":disabled": {
        backgroundColor: theme.colors["bg/InversePrimary"],
      },
    }),
  secondary: (theme: Theme) =>
    css({
      color: theme.colors["bg/InversePrimary"],
      backgroundColor: theme.colors["bg/Secondary"],

      ":hover, :active": {
        backgroundColor: theme.colors.gray200,
      },
      ":disabled": {
        backgroundColor: theme.colors["bg/Secondary"],
      },
    }),
  green: (theme: Theme) =>
    css({
      color: theme.colors["content/InversePrimary"],
      backgroundColor: theme.colors["bg/Accent"],

      ":hover, :active": {
        backgroundColor: theme.colors["bg/Accent"],
      },
      ":disabled": {
        backgroundColor: theme.colors["bg/Accent"],
      },
    }),
  black: (theme: Theme) =>
    css({
      color: theme.colors.white,
      backgroundColor: theme.colors.black,

      ":hover, :active": {
        // NOTE: contained/black의 호버의 경우, 하나의 컬러로 가기로 했습니다.
        backgroundColor: LIGHT_THEME.colors.gray800,
      },
      ":disabled": {
        backgroundColor: theme.colors.black,
      },
    }),
};

const outlineButtonBorderWidth = (size: ButtonSize) => {
  switch (size) {
    case "big":
      return "2px";
    case "large":
      return "1.5px";
    case "medium":
    case "small":
    default:
      return "1px";
  }
};

const outlinedButtonStyles: {
  [key in ButtonColorType<"outlined">]: (
    theme: Theme,
    size: ButtonSize,
  ) => SerializedStyles;
} = {
  primary: (theme: Theme, size: ButtonSize) =>
    css({
      color: theme.colors["bg/InversePrimary"],
      border: `${outlineButtonBorderWidth(size)} solid ${
        theme.colors["bg/InversePrimary"]
      }`,

      ":hover, :active": {
        backgroundColor: theme.colors["bg/InversePrimary"],
        color: theme.colors["bg/Primary"],
      },
    }),
  secondary: (theme: Theme, size: ButtonSize) =>
    css({
      color: theme.colors["bg/InversePrimary"],
      border: `${outlineButtonBorderWidth(size)} solid ${
        theme.colors["border/Primary"]
      }`,

      ":hover, :active": {
        backgroundColor: theme.colors["bg/Secondary"],
      },
    }),
};

const textButtonStyles: {
  [key in ButtonColorType<"text">]: (theme: Theme) => SerializedStyles;
} = {
  primary: (theme: Theme) =>
    css({
      color: theme.colors["bg/InversePrimary"],
      borderStyle: "none",

      ":hover, :active": {
        backgroundColor: theme.colors["bg/Secondary"],
      },
    }),
  secondary: (theme: Theme) =>
    css({
      color: theme.colors["content/Tertiary"],

      ":hover, :active": {
        backgroundColor: theme.colors["bg/Secondary"],
      },
    }),
};

const buttonVariantStyles = ({
  colorScheme,
  theme,
  size,
  variant,
}: {
  colorScheme: ButtonColorScheme;
  size: ButtonSize;
  theme: Theme;
  variant: ButtonVariantType;
}) => {
  switch (variant) {
    case "contained":
      return containedButtonStyles[colorScheme](theme);
    case "outlined":
      return outlinedButtonStyles[colorScheme as ButtonColorType<"outlined">](
        theme,
        size,
      );
    case "text":
      return textButtonStyles[colorScheme as ButtonColorType<"text">](theme);
    default:
      return null;
  }
};

const buttonSizeStyles = ({
  hasRightIcon,
  size,
}: {
  hasRightIcon?: boolean;
  size: ButtonSize;
}) => {
  switch (size) {
    case "big":
      return css`
        height: 56px;
        line-height: 22px;
        font-size: 18px;
        font-weight: 600;
        padding: ${hasRightIcon ? "0 20px 0 24px" : "0 24px"};
      `;
    case "large":
      return css`
        height: 48px;
        line-height: 22px;
        font-size: 16px;
        font-weight: 500;
        padding: ${hasRightIcon ? "0 16px 0 20px" : "0 20px"};
      `;
    case "medium":
      return css`
        height: 34px;
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
        padding: ${hasRightIcon ? "0 10px 0 14px" : "0 14px"};
      `;
    case "small":
      return css`
        height: 28px;
        line-height: 16px;
        font-size: 13px;
        font-weight: 500;
        padding: ${hasRightIcon ? "0 6px 0 10px" : "0 10px"};
      `;
    default:
      return null;
  }
};

export const StyledButton = styled.button<{
  colorScheme: ButtonColorScheme;
  hasRightIcon?: boolean;
  size: ButtonSize;
  variant: ButtonVariantType;
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  border-radius: 50px;

  appearance: none;
  user-select: none;
  outline: none;
  cursor: pointer;

  transition: all 0.2s ease-out;

  ${({ size, hasRightIcon }) => buttonSizeStyles({ size, hasRightIcon })};
  ${({ theme, size, colorScheme, variant }) =>
    buttonVariantStyles({ colorScheme, variant, theme, size })};

  &:disabled {
    cursor: initial;
    pointer-events: none;
    opacity: 0.6;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
`;
