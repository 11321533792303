import Text from "@src/common/components/Atoms/Text";
import Space from "@src/common/components/Atoms/Space";
import { useSession } from "next-auth/react";
import Button from "@src/common/components/Atoms/Button";
import { useRouter } from "next/router";
import { PATHS } from "@src/common/constants/routes";
import {
  SESSION_STATUS,
  getSessionStatus,
} from "@src/common/auth/common/session";
import * as S from "./styled";

function ErrorPage() {
  const { data: session, status } = useSession();
  const userStatus = getSessionStatus(session);
  const isUser = userStatus === SESSION_STATUS.AUTHENTICATED;
  const router = useRouter();

  const handleClickErrorButton = () => {
    if (isUser) {
      return router.push(PATHS.CHARTS);
    }

    return router.push(PATHS.HOME);
  };

  return status !== "loading" ? (
    <S.ErrorContainer>
      <Space mb={8}>
        <Text
          color="content/Primary"
          fontSize="80px"
          lineHeight="120px"
          weight={600}
          ellipsis
        >
          앗!
        </Text>
      </Space>
      <Space mb={40}>
        <Text
          color="content/Secondary"
          fontSize="15px"
          lineHeight="24px"
          weight={500}
          textAlign="center"
          whiteSpace="break-spaces"
        >
          요청하신 페이지를 찾을 수 없어요.{"\n"}
          입력하신 주소가 정확한지 확인해주세요.
        </Text>
      </Space>
      <Space mb={16}>
        <Text color="content/Secondary" fontSize="13px" weight={400}>
          이 화면을 찾고있나요?
        </Text>
      </Space>

      <S.ErrorButtonBox>
        <Button
          onClick={handleClickErrorButton}
          type="button"
          variant="outlined"
          colorScheme="primary"
          size="big"
          style={{
            width: "200px",
            height: "56px",
          }}
        >
          {isUser ? "멀티차트 화면" : "도미노 홈페이지"}
        </Button>
      </S.ErrorButtonBox>
    </S.ErrorContainer>
  ) : (
    <></>
  );
}

export default ErrorPage;
