import styled from "@emotion/styled";

export const Space = styled.div<{
  m?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  mt?: number;
}>`
  ${({ m }) => m && `margin : ${m}px;`}
  ${({ mb }) => mb && `margin-bottom : ${mb}px;`}
  ${({ mt }) => mt && `margin-top : ${mt}px;`}
  ${({ ml }) => ml && `margin-left : ${ml}px;`}
  ${({ mr }) => mr && `margin-right : ${mr}px;`}
`;
