import { Session } from "next-auth";

export const SESSION_STATUS = {
  NO_SESSION: "NO_SESSION",
  NO_ACCESS_TOKEN: "NO_ACCESS_TOKEN",
  NO_PROFILE: "NO_PROFILE",
  NO_AGREEMENT: "NO_AGREEMENT",
  AUTHENTICATED: "AUTHENTICATED",
};

export const getSessionStatus = (session: Session | null) => {
  if (!session) {
    return SESSION_STATUS.NO_SESSION;
  }

  if (!session.accessToken || !session.refreshToken) {
    return SESSION_STATUS.NO_ACCESS_TOKEN;
  }

  if (!session.profileStatus) {
    return SESSION_STATUS.NO_PROFILE;
  }

  if (!session.agreementStatus) {
    return SESSION_STATUS.NO_AGREEMENT;
  }

  return SESSION_STATUS.AUTHENTICATED;
};
