import { forwardRef, ForwardedRef } from "react";
import { StyledButton } from "./styled";
import ButtonIcon from "./ButtonIcon";
import type { ButtonProps, ButtonVariantType } from "./types";

const Button = forwardRef(function Button<
  VariantType extends ButtonVariantType,
>(
  { children, rightIcon, ...rest }: ButtonProps<VariantType>,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const { size } = rest;

  return (
    <StyledButton ref={ref} hasRightIcon={!!rightIcon} {...rest}>
      {children}
      {rightIcon && <ButtonIcon size={size}>{rightIcon}</ButtonIcon>}
    </StyledButton>
  );
});

export default Button;
