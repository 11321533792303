import styled from "@emotion/styled";

export const ErrorContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors["bg/Primary"]};
`;

export const ErrorButtonBox = styled.div`
  width: fit-content;
`;
