import React, { ReactNode } from "react";
import * as S from "./styled";

interface SpaceProps {
  children: ReactNode;
  m?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  mt?: number;
}

const Space = ({ children, ...props }: SpaceProps) => {
  return <S.Space {...props}>{children}</S.Space>;
};

export default Space;
