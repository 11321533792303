import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { ColorToken } from "@src/common/theme/types";
import { TextProps } from "./types";

const getColor = (theme: Theme, color: keyof ColorToken["colors"]): string => {
  return theme.colors[`${color}`];
};

export const Text = styled.div<TextProps>`
  color: ${({ theme, color }) =>
    color ? getColor(theme, color) : theme.colors["content/Primary"]};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  opacity: ${({ opacity }) => opacity};

  ${({ whiteSpace }) =>
    whiteSpace &&
    `
    white-space:${whiteSpace};
  `};

  ${({ textAlign }) =>
    textAlign &&
    `
  text-align:${textAlign};
  `};

  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;`}
`;
