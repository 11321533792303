import { StyledButtonIcon } from "./styled";
import type { ButtonIconProps } from "./types";

const ButtonIcon = ({ children, ...rest }: ButtonIconProps) => {
  return (
    <StyledButtonIcon aria-hidden tabIndex={-1} {...rest}>
      {children}
    </StyledButtonIcon>
  );
};

export default ButtonIcon;
